const CONSTANTS = {
  // todo change url
  // apiBaseURL: 'https://dev-api.worktually.com/api/v2',
  // apiImagesURL: 'https://worktuallydev.nyc3.digitaloceanspaces.com/',
  // pusherAuthURL: 'https://dev-api.worktually.com/api/v2/broadcast/auth',
  // videoBaseURL: 'https://worktuallydev.nyc3.digitaloceanspaces.com',

  apiBaseURL: 'https://demo-api.worktually.com/api/v2',
  apiImagesURL: 'https://worktuallydev.nyc3.digitaloceanspaces.com/',
  pusherAuthURL: 'https://demo-api.worktually.com/api/v2/broadcast/auth',
  videoBaseURL: 'https://worktuallydev.nyc3.digitaloceanspaces.com',

  // apiBaseURL: 'https://demo-api.worktually.com/api/v2',
  // apiImagesURL: 'https://worktuallydev.nyc3.digitaloceanspaces.com/',
  // pusherAuthURL: 'https://qa-api.worktually.com/api/v2/broadcast/auth',
  // videoBaseURL: 'https://worktuallydev.nyc3.digitaloceanspaces.com',

  // apiBaseURL: "https://165.227.44.69/api/v2",
  // apiImagesURL: "https://165.227.44.69/uploads/",

  //local
  // apiBaseURL: "https://worktually-2.0-api.test/api/v2",
  // apiImagesURL: "https://worktuallydev.nyc3.digitaloceanspaces.com/",
  // pusherAuthURL: "http://worktually.test/api/v2/broadcast/auth",

  // apiBaseURL: "https://api.worktually.com/api/v2",
  // apiImagesURL: "https://worktually-v2.nyc3.digitaloceanspaces.com/",
  // pusherAuthURL: "https://api.worktually.com/api/v2/broadcast/auth",
  // videoBaseURL: 'https://worktually-v2.nyc3.digitaloceanspaces.com',

  // apiBaseURL: "https://final-api.worktually.com/api/v2",
  // apiImagesURL: "https://worktuallydev.nyc3.digitaloceanspaces.com/",
  // pusherAuthURL: "https://final-api.worktually.com/api/v2/broadcast/auth",

  drawerWidth: 320,
  drawerHeight: 120,

  adminScreenLeftGutter: '7.5rem',
  adminScreenRightGutter: '7.5rem',

  adminNavigationHeight: '8.5rem',

  adminSidebarWidth: '22rem',

  Sitename: 'Worktually',
  version: '2.4.2',
};

export default CONSTANTS;
